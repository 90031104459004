a {
  text-decoration: none;
}

.error-back {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/login-left-app.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: cover;
  z-index: 10;
  /* text-align: center; */
  display: flex;
  justify-content: center;
}

.titulo-404 {
  font-size: 200px;
  font-weight: bold;
  margin-top: 25vh;
}

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 80vw;
  color: white;
}

/* .center404 {
  width: 50%;
  min-height: 100vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: contain;
  z-index: 11;
  margin: auto;
} */

.bottom-404 {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  margin-bottom: 40px;
}

@media (max-width: 800px) {
  /* .bottom-404 {
    margin-top: 30px;
    height: 100% !important;
  } */
  .mainContent {
    color: white;
  }
}
