body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'poppins', 'Roboto',
    s sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 1.3em;
  height: 0;
}
::-webkit-scrollbar-thumb {
  height: 1em;
  border: 0.5em solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 1em;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
